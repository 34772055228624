import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"

// markup
const AGB = () => {
  return (
    <Layout pageTitle="Allgemeine Geschäftsbestimmungen">
      <h4>$ 1 Geltungsbereich</h4>
      <p>(1) Alle über die Plattform „Apotheken Direkt“ abgewickelten Lieferungen und Leistungen der Engel-Apotheke, Renate Koehler e. K., Rheinstr. 7-9, 64283 Darmstadt, erfolgen ausschließlich auf Basis der nachfolgenden Allgemeinen Geschäftsbedingungen in der zum Zeitpunkt der Bestellung gültigen Fassung. Von diesen Bedingungen abweichenden Einkaufsbedingungen wird widersprochen, es sei denn, sie werden von der Apotheke ausdrücklich schriftlich bestätigt.</p>
      <p>(2) Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten ferner für Arzneimittelbestellungen, die über die Plattform „Apotheken Direkt“ mit den einzelnen Apotheken angebahnt werden. Die Liste der jeweils teilnehmenden Apotheken kann <Link to='/'>hier</Link> eingesehen werden.</p>
      <h4>§ 2 Vertragsabschluss</h4>
      <p>(1) Die Darstellung der Produkte im Internet, auf Druckerzeugnissen und in jegliche anderer Werbeform ist freibleibend und stellt kein bindendes Angebot dar, sondern eine Aufforderung an den Kunden ein Angebot auf Abschluss eines Vertrages abzugeben.</p>
      <p>(2) Die Bestellung erfolgt durch Übermittlung eines vollständig ausgefüllten Bestellformulars im Onlineverfahren, per Telefon, per Post oder per Fax. Sofern die Bestellung auf der Vorbestellplattform durch das Anklicken des Buttons „Jetzt vorbestellen“ auf der Warenkorb-Seite abgegeben wird, gibt der Kunde eine unverbindliche Bestellung der im Warenkorb enthaltenen Produkte ab.</p>
      <p>(3) Das Angebot wird durch die Apotheke angenommen und der Vertrag wirksam, wenn die den Vertragsschluss bestätigt. Die automatisch per E-Mail versandte Bestellbestätigung stellt keine Auftragsbestätigung bzw. keine Annahme des Angebots des Kunden dar, sondern informiert den Kunden nur über den Eingang der Bestellung. Sollte innerhalb von 3 Werktagen nach Zugang der Bestellbestätigung weder eine Lieferung noch eine ausdrückliche Auftragsbestätigung zugehen, ist der Kunde an das Vertragsangebot nicht mehr gebunden.</p>
      <p>(4) Sind die bestellten Waren vorrätig, informiert die Apotheke den Kunden darüber, dass die bestellten Waren in der Apotheke abgeholt werden können, sofern nicht im Einzelfall eine Lieferung durch Boten vereinbart ist. Die Annahme des Angebots des Kunden erfolgt dann erst durch die Apotheke mit Abholung der Waren in der Apotheke oder mit deren Aushändigung an den Kunden im Falle der Belieferung durch den Boten.</p>
      <p>(5) Sollte ein bestelltes Produkt nicht lieferbar sein, bzw. nicht unter zumutbaren Bedingungen von der Apotheke beschafft werden können, wird der Kunde hierrüber unverzüglich informiert und etwa durch diesen geleisteten Zahlungen werden zurück erstattet.</p>
      <p>(6) Sollte ein bestelltes Produkt, dass von einem Arzt verschrieben wurde, nicht lieferbar sein, bzw. nicht unter zumutbaren Bedingungen von der Apotheke beschafft werden können, ist die Apotheke berechtigt, den Arzt zu kontaktieren, um mit diesem eine Alternative zu besprechen.</p>
      <p>(7) Die Apotheke kann die Abgabe eines Arzneimittels zu jeder Zeit ablehnen, wenn zu dessen sicherer Anwendung ein Informations- oder Beratungsbedarf besteht, der auf einem anderen Wege als einer persönlichen Information oder Beratung durch einen Apotheker nicht erfolgen kann. Eine Abgabe kann darüber hinaus zu jeder Zeit bei begründetem Verdacht auf Arzneimittelmissbrauch verweigert werden. Sofern der Kunde verschreibungspflichtiger Arzneimittel vorbestellt hat, kann die Apotheke ferner die Abgabe jederzeit verweigern, sofern zum Zeitpunkt der Abholung keine ordnungsgemäße Verschreibung über die vorbestellten Arzneimittel vorliegt.</p>
      <h4>§ 3 Preise und Lieferkosten</h4>
      <p>(1) Die Preise verstehen sich in Euro und enthalten die gesetzliche Mehrwertsteuer. Es gelten die zum Zeitpunkt des Eingangs der Bestellung gültigen Preise.</p>
      <p>(2) Der Kaufpreis ist unmittelbar mit Vertragsschluss, d. h. mit Erhalt der Ware, fällig. Die Zahlung erfolgt wahlweise per  Paypal, Kreditkarte, ggf. durch Rezept oder durch Barzahlung bei Abholung oder Lieferung. Die Apotheke behält sich vor, im Einzelfall bestimmte Zahlungsarten nicht anzubieten, insbesondere nach dem Ergebnis einer Bonitätsprüfung.</p>
      <p>(3) Der Kunde kommt ohne Mahnung in Verzug, wenn ein Ausgleich nicht innerhalb von 30 Kalendertagen ab Erhalt der Ware sowie Zugang einer Rechnung erfolgt. Die Apotheke ist berechtigt, Verzugszinsen in Höhe von 5%-Punkten über dem Basiszinssatz zu berechnen. Die Geltendmachung eines darüber hinausgehende Schadens bleibt vorbehalten.</p>
      <p>(4) Wird im Einzelfall die Lieferung per Bote vereinbart, erfolgt dies gemäß der insoweit bei Bestellung spezifizierten anfallenden Kosten.</p>
      <p>(5) Die bestellte Ware bleibt bis zur vollständigen Bezahlung Eigentum der Apotheke.</p>
      <h4>§ 4 Lieferung/Übergabe</h4>
      <p>(1) Soweit nicht anders vereinbart, holt der Kunde die Arzneimittel in der jeweiligen Apotheke ab. Die Apotheke verpflichtet sich, die vorbestellten Waren für 3 Werktage nach Übermittlung der Bestätigung an den Kunden vorzuhalten.</p>
      <p>(2) Soweit im Einzelfall eine Lieferung per Boten der Apotheke vereinbart ist, erfolgt dies nach Maßgabe der hierfür geltenden Vorschriften, insbesondere § 17 Abs. 2 ApoBetrO.</p>
      <h4>§ 5 Gewährleistung</h4>
      <p>(1) Die auf der Web-Seite der Apotheke festgelegten Beschaffenheiten legen die Eigenschaften des Liefergegenstandes umfassend und abschließend fest. Insbesondere enthalten öffentlichen Äußerungen der Apotheke, sowie die des Herstellers, von Gehilfen oder Dritten (z. B. Darstellungen von Produkteigenschaften in der Öffentlichkeit) keine Leistungsbeschreibung ergänzende oder verändernde Beschreibungen des Liefergegenstandes. Erklärungen im Zusammenhang mit diesem Vertrag (z. B Leistungsbeschreibungen, Bezugnahme auf DIN-Normen usw.) enthalten keine Übernahme einer Garantie. Die Übernahme einer Garantie bedarf einer ausdrücklichen schriftlichen Erklärung der Apotheke.</p>
      <p>(2) Die Gewährleistung erfolgt nach den gesetzlichen Bestimmungen, wobei die Apotheke berechtigt ist, im Falle eines Mangels der Ware nach ihrer Wahl nachzuliefern oder nachzubessern. Schlägt die Nachbesserung endgültig fehl oder ist die nachgelieferte Ware ebenfalls mangelbehaftet, so kann der Kunde Rückerstattung des vereinbarten Preises gegen Rückgabe der Ware oder Herabsetzung des Kaufpreises verlangen. Die Gewährleistungsfrist beträgt 2 Jahre.</p>
      <h4>§ 6 Haftung</h4>
      Die Apotheke haftet nur in Fällen, in denen ihr, einem gesetzlichen Vertreter oder einem Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fallen. Vorstehendes gilt nicht, soweit für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit gehaftet wird, für die Haftung nach dem Produkthaftungsgesetz sowie im Falle der Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Außer bei Vorsatz, grober Fahrlässigkeit und Schäden aus der Verletzung des Lebens, des Körpers und der Gesundheit ist die Haftung der Höhe nach auf die beim Vertragsschluss typischer Weise vorhersehbaren Schäden begrenzt.
      <h4>§ 7 Datenschutz</h4>
      Hinsichtlich des Datenschutzes wird auf die insoweit geltende Grundzüge der Datenverarbeitung verwiesen, die <Link to="/datenschutz">hier</Link> eingesehen werden können.
      <h4>§ 8 Sonstiges</h4>
      <p>(1) Es gilt das Recht der Bundesrepublik Deutschland; die Geltung des UN–Kaufrechts ist ausgeschlossen. </p>
      <p>(2) Sofern es sich beim Kunden um einen Kaufmann oder eine juristische Person des öffentlichen Rechts handelt, ist Darmstadt. Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen; die Apotheke ist in einem solchen Fall aber auch berechtigt, den Kunden an seinem Wohnsitzgericht zu verklagen.</p>
      <p>(3) Sollte eine der vorstehenden Bestimmungen unwirksam sein oder werden, so wird die Wirksamkeit des Vertrages im Übrigen davon nicht berührt.</p>
    </Layout>
  )
}

export default AGB
